import { CrudService } from 'src/app/services/crudService';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from 'src/app/services/loginService';
import { Injectable } from '@angular/core';
import { TimeZoneService } from '../time-zone/time-zone.service';
import { I18nServiceService } from '@services/i18n-service.service';
import { AlertaService } from '@services/alerta/alerta.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private userAll: any;
  private user = new BehaviorSubject<any>(null);
  public userInfo = this.user.asObservable();

  private userImg = new BehaviorSubject<any>('');
  public userImgId = this.userImg.asObservable();

  private empresaBase = new BehaviorSubject<any>('');
  public empreBase = this.user.asObservable();

  private empresaPadre = new BehaviorSubject<any>('');
  public emprePadre = this.user.asObservable();

  public idEmpresaPadre= new BehaviorSubject<any>('');
  public idEmpPadre= this.user.asObservable();

  private preference = new BehaviorSubject<any>('');
  public preferenceUser = this.preference.asObservable();

  private userFormDate = new BehaviorSubject<any>('');
  public userFormDate$ = this.userFormDate.asObservable();

  private isLoading = new BehaviorSubject<any>(false);
  public isLoading$ = this.isLoading.asObservable();

  private isLogin = new BehaviorSubject<any>(false);
  public isLogin$ = this.isLogin.asObservable();

  private maxScrollHeight = new BehaviorSubject<any>(0);
  public maxScrollHeight$ = this.maxScrollHeight.asObservable();
  private formatSubject = new BehaviorSubject<number>(0);

  constructor(
    private crudSvc: CrudService,
    private timeZoneSvc: TimeZoneService,
    private i18Service: I18nServiceService,
    private alerta: AlertaService,
    private router: Router,
  ) {
  }

  public changeUser(userNew: any, idioma?: boolean){
    let auxiUser = this.userAll;
    const keys1 = Object.keys(auxiUser);
    const keys2 = Object.keys(userNew);

    if(keys1.length != keys2.length){
      for(let key of keys1){
        if(keys2.includes(key) && auxiUser[key] != userNew[key]){
          auxiUser[key] = userNew[key];
        }
      }
    }
    this.user.next(auxiUser);
    if(idioma){
      this.i18Service.changeLanguagePreferece(userNew, this.preference.getValue());
    }
  }

  public initUser(user){
    this.userAll = user;
    this.user.next(user);
    this.getPreference(user.Company.idEmpresaPadre, user);
  }

  public changeImgUser(url: any){
    this.userImg.next(url);
  }

  public changeEmpBase(company){
    if(company.idEmpresa == this.userAll.idEmpresa){
      this.empresaBase.next(company);
    }
  }

  public changeEmpPadre(company){
    if(company.idEmpresa == this.userAll?.idEmpresa){
      this.empresaPadre.next(company);
    }else if(company.idEmpresaPadre !== null){
      this.idEmpresaPadre.next(company.idEmpresaPadre)
    }else{
      //si no trae idEmpresa Padre, pone idEmpresa
      this.idEmpresaPadre.next(company.idEmpresa)
    }
  }

  public changeFormDate(data){
    localStorage.setItem('formatDate',JSON.stringify(data));
    this.userFormDate.next(data);
  }

  public changeIsLoading(data){
    let maxScrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    this.maxScrollHeight.next(maxScrollHeight);
    this.isLoading.next(data);
  }

  public changeIsLogin(data){
    this.isLogin.next(data);
  }

  public changePreference(data){
    this.preference.next(data);
  }

  public getPreference(idEmpresaPadre, user?){
    this.crudSvc.getEntity('preference',idEmpresaPadre).subscribe(
      prefer =>{
        let { preference } = prefer.body;
        if(preference){
          this.timeZoneSvc.setCurrentTimeZone(preference.zonahoraria);
          this.setFormatNumber(preference)
        }else{
          this.timeZoneSvc.setCurrentTimeZone(+0);
        }
        
/*      if(preference.epsgCoordenadas === null){
          preference.epsgCoordenadas = 3116
        } */

        this.changePreference(preference);
        this.setDataDefault(user, preference);
        this.i18Service.changeLanguagePreferece(user, preference);
    },
    error =>{
      this.alerta.openSnackAlert("Fallo al obtener las preferencias, comuniquese con su administrador", "Error");
      this.router.navigate(['/sso/login']);
    });
  }

  get format$() {
    return this.formatSubject.asObservable();
  }

  setFormatNumber(preference){
    this.formatSubject.next(preference.formatoNumero);
  }

  formatNumber(value: string): string {
    let format = this.formatSubject.getValue();
    if (value === null || value === undefined) return '';
    
    let numStr = value.toString().trim();
    
    if (format !== 0) {
      if (parseInt(numStr.replace(/[.,]/g, ''), 10) < 1000) {
        if (format === 1) {
          return numStr.includes('.') ? numStr.replace(/\./g, ',') : numStr;
        } else {
          return numStr.includes(',') ? numStr.replace(/,/g, '.') : numStr;
        }
      }
    
      let hasComma = numStr.includes(',');
      let hasDot = numStr.includes('.');
      let thousandSeparator = '';
      let decimalSeparator = '';
    
      if (hasComma && hasDot) {
        if (numStr.lastIndexOf(',') > numStr.lastIndexOf('.')) {
          thousandSeparator = '.';
          decimalSeparator = ',';
        } else {
          thousandSeparator = ',';
          decimalSeparator = '.';
        }
      } else if (hasComma) {
        thousandSeparator = ',';
        decimalSeparator = '.';
      } else if (hasDot) {
        thousandSeparator = '.';
        decimalSeparator = ',';
      } else {
        decimalSeparator = '.';
      }
    
      let cleanNumber = numStr.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
    
      cleanNumber = cleanNumber.replace(decimalSeparator, '.');
    
      let numericValue = parseFloat(cleanNumber);
      if (isNaN(numericValue)) return numStr;
    
      let newThousandSeparator = format === 1 ? ',' : '.';
      let newDecimalSeparator = format === 1 ? '.' : ',';
    
      let [integerPart, decimalPart] = numericValue.toString().split('.');
    
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, newThousandSeparator);
    
      return decimalPart && decimalPart !== '00' ? `${integerPart}${newDecimalSeparator}${decimalPart}` : integerPart;
    }
    
    return numStr;
  }

  public setDataDefault(user, company){
    if(user && company){
      let langs = [
        {name: 'MM/DD/YYYY', pipe: 'MM/dd/YYYY'},
        {name: 'DD/MM/YYYY', pipe: 'dd/MM/YYYY'},
      ];

      if(company.epsgCoordenadas){
        localStorage.setItem('epsg', company.epsgCoordenadas)
      }

      if(user.formatoFecha){
        if(user.formatoFecha == 'MM/DD/YYYY')localStorage.setItem('formatDate',JSON.stringify(langs[0]))
        if(user.formatoFecha == 'DD/MM/YYYY')localStorage.setItem('formatDate',JSON.stringify(langs[1]))
        this.timeZoneSvc.setFormatDate();
      }else{
        if (company.formatofecha){
          if(company.formatofecha == 'MM/DD/YYYY')localStorage.setItem('formatDate',JSON.stringify(langs[0]))
          if(company.formatofecha == 'DD/MM/YYYY')localStorage.setItem('formatDate',JSON.stringify(langs[1]))
          this.timeZoneSvc.setFormatDate();
        }else{
          localStorage.setItem('formatDate',JSON.stringify(langs[1]))
          this.timeZoneSvc.setFormatDate();
        }
      }
    }
  }

  public getAllDataUser(){
    return this.userAll
  }
}
